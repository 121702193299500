import { Product } from "store/catalogo/reducer";

export const ProductReturnStock = (product: Product | undefined) => {
  try {
    if (!product) {
      return 0;
    }

    if (product.check_stock === "yes" && product.stock <= 0) {
      return 0;
    }

    if (product.check_stock === "no") {
      return 0;
    }

    return product.stock;
  } catch (error) {
    return 0;
  }
};
