import { createSlice } from "@reduxjs/toolkit";

export const KEY_CUSTOMER = `customer-${
  window.location.pathname.split("/")[1]
}`;

export interface ICustomer {
  _id: string;
  name: string;
  phone: string;
  email: string;
  birthday: string;
  is_client_new?: boolean;
  is_enabled_to_program_points: boolean;
  actual_qtd_program_points: number;
  receive_email?: boolean;
  address?: string;
  neighborhood?: string;
  complement?: string;
  number?: string;
  addressType?: string;
}

export interface ICustomerState {
  customer: ICustomer;
}

const initialState: ICustomerState = {
  customer: JSON.parse(localStorage.getItem(KEY_CUSTOMER) || "{}"),
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      Object.assign(state.customer, action.payload);
      localStorage.setItem(KEY_CUSTOMER, JSON.stringify(action.payload));
    },
  },
});
