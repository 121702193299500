import { Transition } from "@headlessui/react";
import { Typography } from "antd";
import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ProductToOrder } from "store/cart/reducer";

interface Props {
  show: boolean;
  productImage: string;
  variantActive: number;
  sizeSelected: string;
  qualitySelected: number;
  product?: ProductToOrder;
}

const NotifyAddTocart: FC<Props> = ({
  show,
  productImage,
  variantActive,
  qualitySelected,
  sizeSelected,
  product,
}) => {
  const { name, price, variants } = PRODUCTS[0];
  if (!product) return null;

  const renderProductCartOnNotify = () => {
    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={product?.photo || productImage}
            alt={product.name}
            className="h-full w-full object-contain object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{product.name}</h3>
                <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }}>
                  {product.description}
                </Typography.Paragraph>
              </div>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <Prices
              price={product.finish_value}
              className="mt-0.5 text-nowrap"
            />
            <p className="text-gray-500 dark:text-slate-400">{`Qtd ${qualitySelected}`}</p>

            {/* <div className="flex">
              <Link
                to={`/cart`}
                className="font-medium text-primary-6000 dark:text-primary-500 "
              >
                Ver carrinho
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 max-w-md w-full bg-white dark:bg-slate-800     pointer-events-auto  ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200">
      <p className="block text-base font-semibold leading-none">
        Adicionado ao carrinho
      </p>
      <hr className=" border-slate-200 dark:border-slate-700 my-4" />
      {renderProductCartOnNotify()}
    </div>
  );
};

export default NotifyAddTocart;
