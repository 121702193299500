import CardCategory1 from "components/CardCategories/CardCategory1";
import CardCategory4 from "components/CardCategories/CardCategory4";
import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import React, { FC, useCallback } from "react";
import Nav from "shared/Nav/Nav";
import explore1Svg from "images/collections/explore1.svg";
import explore2Svg from "images/collections/explore2.svg";
import explore3Svg from "images/collections/explore3.svg";
import explore4Svg from "images/collections/explore4.svg";
import explore5Svg from "images/collections/explore5.svg";
import explore6Svg from "images/collections/explore6.svg";
import explore7Svg from "images/collections/explore7.svg";
import explore8Svg from "images/collections/explore8.svg";
import explore9Svg from "images/collections/explore9.svg";
//
import explore1Png from "images/collections/explore1.png";
import explore2Png from "images/collections/explore2.png";
import explore3Png from "images/collections/explore3.png";
import explore4Png from "images/collections/explore4.png";
import explore5Png from "images/collections/explore5.png";
import explore6Png from "images/collections/explore6.png";
import explore7Png from "images/collections/explore7.png";
import explore8Png from "images/collections/explore8.png";
import explore9Png from "images/collections/explore9.png";
import CardCategory6 from "components/CardCategories/CardCategory6";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IGallery } from "store/catalogo/reducer";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import Utils from "utils/utils";
import Logo from "shared/Logo/Logo";

interface ExploreType {
  id: number;
  name: string;
  desc: string;
  image: string;
  svgBg: string;
  color?: string;
}

export interface SectionGridMoreExploreProps {
  className?: string;
  gridClassName?: string;
  boxCard?: "box1" | "box4" | "box6";
  data?: ExploreType[];
}

export const DEMO_MORE_EXPLORE_DATA = [
  {
    id: 1,
    name: "Backpack",
    desc: "Manufacturar",
    image: explore1Png,
    svgBg: explore1Svg,
    color: "bg-indigo-50",
  },
  {
    id: 2,
    name: "Shoes",
    desc: "Manufacturar",
    image: explore2Png,
    svgBg: explore2Svg,
    color: "bg-slate-100/80",
  },
  {
    id: 3,
    name: "Recycled Blanket",
    desc: "Manufacturar",
    image: explore3Png,
    svgBg: explore3Svg,
    color: "bg-violet-50",
  },
  {
    id: 4,
    name: "Cycling Shorts",
    desc: "Manufacturar",
    image: explore9Png,
    svgBg: explore9Svg,
    color: "bg-orange-50",
  },
  {
    id: 5,
    name: "Cycling Jersey",
    desc: "Manufacturar",
    image: explore5Png,
    svgBg: explore5Svg,
    color: "bg-blue-50",
  },
  {
    id: 6,
    name: "Car Coat",
    desc: "Manufacturar",
    image: explore6Png,
    svgBg: explore6Svg,
    color: "bg-orange-50",
  },
  {
    id: 7,
    name: "Sunglasses",
    desc: "Manufacturar",
    image: explore7Png,
    svgBg: explore7Svg,
    color: "bg-stone-100",
  },
  {
    id: 8,
    name: "kid hats",
    desc: "Manufacturar",
    image: explore8Png,
    svgBg: explore8Svg,
    color: "bg-blue-50",
  },
  {
    id: 9,
    name: "Wool Jacket",
    desc: "Manufacturar",
    image: explore4Png,
    svgBg: explore4Svg,
    color: "bg-slate-100/80",
  },
];

const SectionGridMoreExplore: FC<SectionGridMoreExploreProps> = ({
  className = "",
  boxCard = "box4",
  gridClassName = "grid-cols-1 md:grid-cols-2 xl:grid-cols-3",
  data = DEMO_MORE_EXPLORE_DATA.filter((_, i) => i < 6),
}) => {
  const [tabActive, setTabActive] = React.useState("Man");

  const { catalogo } = useSelector((state: RootState) => state.catalogo.data);
  const [searchValue, setSearchValue] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<IGallery[]>(
    catalogo.gallery
  );

  const onSearch = (value: string) => {
    const dataForFilter = catalogo.gallery.map((item) => {
      return {
        ...item,
        name: item.catalog.name || "",
        desc: item.catalog.description,
      };
    });
    const data = Utils.wildCardSearch(dataForFilter, value);
    setFilteredData(data);
  };

  const renderCard = useCallback(
    (item: IGallery, index) => {
      const catalog = item.catalog;

      if (!catalog) return null;

      switch (boxCard) {
        case "box1":
          return (
            <CardCategory1
              key={catalog._id}
              name={catalog.name}
              desc={catalog.description}
              featuredImage={catalog.photo}
            />
          );

        case "box4":
          return (
            <CardCategory4
              name={catalog.name}
              payload={item}
              desc={catalog.description || ""}
              bgSVG={DEMO_MORE_EXPLORE_DATA[index]?.svgBg || explore1Svg}
              featuredImage={catalog.photo}
              key={catalog._id}
            />
          );
        case "box6":
          return (
            <CardCategory6
              name={catalog.name}
              desc={catalog.description || ""}
              bgSVG={DEMO_MORE_EXPLORE_DATA[index]?.svgBg || explore1Svg}
              featuredImage={catalog.photo}
              key={catalog._id}
            />
          );

        default:
          return (
            <CardCategory4
              payload={item}
              name={catalog.name}
              desc={catalog.description || ""}
              bgSVG={DEMO_MORE_EXPLORE_DATA[index]?.svgBg || explore1Svg}
              featuredImage={catalog.photo}
              key={catalog._id}
            />
          );
      }
    },
    [boxCard]
  );

  const renderHeading = () => {
    return (
      <div>
        <div className="flex justify-center mb-4">
          <Logo />
        </div>
        <Heading
          className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
          isCenter
          desc=""
        >
          {catalogo?.name}
        </Heading>
        <div className="container mb-10">
          <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
            <form
              className="relative w-full"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <label
                htmlFor="search-input"
                className="text-neutral-500 dark:text-neutral-300"
              >
                <span className="sr-only">Pesquisa</span>
                <Input
                  className="shadow-lg border-0 dark:border"
                  id="search-input"
                  type="search"
                  placeholder="Pesquisa"
                  sizeClass="pl-14 py-5 pr-5 md:pl-16"
                  rounded="rounded-full"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    onSearch(e.target.value);
                  }}
                />
                <ButtonCircle
                  className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                  size=" w-11 h-11"
                  type="submit"
                >
                  <i className="las la-arrow-right text-xl"></i>
                </ButtonCircle>
                <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </label>
            </form>
          </header>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionGridMoreExplore relative ${className}`}
      data-nc-id="SectionGridMoreExplore"
    >
      {renderHeading()}
      <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
        {filteredData.map((item, index) => renderCard(item, index))}
      </div>
    </div>
  );
};

export default SectionGridMoreExplore;
