import axios from "axios";

export const fireWebhook = async (url: string, data: any = undefined) => {
  try {
    if (!url) return;
    await axios({
      baseURL: url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Erro ao enviar webhook", error);
  }
};
