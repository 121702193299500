import { Popover, Transition } from "@headlessui/react";
import { Tag } from "antd";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { isOpenWorkTime } from "utils/isOpenWorkTime";

export default function OperationTime() {
  const catalogo = useSelector(
    (state: RootState) => state.catalogo.data.catalogo
  );

  if (!catalogo) {
    return null;
  }

  return (
    <div className="AvatarDropdown ">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={` 
                 group w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className=" md:block absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 -right-0 top-24 sm:top-10 sm:right-0 ">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                  <div className="relative bg-white dark:bg-neutral-800">
                    <div className="max-h-[60vh] p-5 overflow-y-auto hiddenScrollbar">
                      <h3 className="text-xl font-semibold">
                        Horário de funcionamento
                        {isOpenWorkTime(catalogo.operator_time) ? (
                          <Tag color="green" className="ml-2">
                            Aberto
                          </Tag>
                        ) : (
                          <Tag color="red" className="ml-2">
                            Fechado
                          </Tag>
                        )}
                      </h3>
                      <div className="divide-y divide-slate-100 dark:divide-slate-700">
                        {catalogo.operator_time.filter((d) => d.active)
                          .length === 0 && (
                          <div className="text-center py-5">
                            <p className="text-slate-500 dark:text-slate-400">
                              Sem horário de funcionamento definido
                            </p>
                          </div>
                        )}

                        {catalogo?.operator_time
                          ?.filter((d) => d.active)
                          .map((item, index) => (
                            <div key={index} className="py-5">
                              <h4 className="text-lg font-semibold">
                                {item.dia}
                              </h4>
                              <div className="grid grid-cols-2 gap-3 mt-3">
                                {item.turno.map((turno, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center"
                                  >
                                    <span className="text-slate-500 dark:text-slate-400">
                                      {turno.inicio} - {turno.fim}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="bg-neutral-50 dark:bg-slate-900 p-5">
                      <p className="flex justify-between font-semibold text-slate-900 dark:text-slate-100">
                        <span>
                          <span className="block text-sm text-slate-500 dark:text-slate-400 font-normal">
                            Esses horários foram definidos pelo dono do
                            estabelecimento.
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
