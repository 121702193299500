import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import { RootState, useAppDispatch } from "store";
import { useSelector } from "react-redux";
import { cartSlice, ProductToOrder } from "store/cart/reducer";
import { Button, Popconfirm, Tag } from "antd";
import { currencyFormatter } from "utils/currencyFormatter";
import { httpClient } from "services/api";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { customerSlice } from "store/customer/reducer";
import { makeResumeCartInText } from "utils/makeResumeCartInText";
import ShippingAddress from "./ShippingAddress";
import { isOpenWorkTime } from "utils/isOpenWorkTime";

const CheckoutPage = () => {
  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ContactInfo");
  const navigate = useNavigate();
  const cart = useSelector((state: RootState) => state.cart.order);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const catalog = useSelector(
    (state: RootState) => state.catalogo.data.catalogo
  );
  const programPoints = useSelector(
    (state: RootState) => state.catalogo.data.programPoints
  );
  const dispatch = useAppDispatch();
  const [couponCode, setCouponCode] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isReferralCodeLoading, setIsReferralCodeLoading] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [loaddingSendOrder, setLoaddingSendOrder] = useState(false);
  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  useEffect(() => {
    handleScrollToEl(tabActive);
  }, [tabActive]);

  useEffect(() => {
    if (!cart || cart.products.length === 0) {
      navigate(`/${window.location.pathname.split("/")[1]}`);
    }
  }, [cart, navigate]);

  const hadnleApplyReferralCode = async () => {
    try {
      if (!referralCode) return;
      setIsReferralCodeLoading(true);

      const { data } = await httpClient({
        url: `/public/mount-catalogo/referre/by-code`,
        method: "GET",
        params: {
          catalog: window.location.pathname.split("/")[1],
          code: referralCode,
        },
      });
      setIsReferralCodeLoading(false);
      dispatch(cartSlice.actions.changeReferrerCode({ code: data }));
    } catch (error) {
      toast.error("Código de indicação inválido");
      setIsReferralCodeLoading(false);
    }
    setIsReferralCodeLoading(false);
  };

  const handleApplyCoupon = async () => {
    try {
      if (!couponCode) return;
      setIsCouponLoading(true);

      const { data } = await httpClient({
        url: `/public/mount-catalogo/coupon/by-code`,
        method: "GET",
        params: {
          catalog: window.location.pathname.split("/")[1],
          code: couponCode,
        },
      });
      setIsCouponLoading(false);
      dispatch(cartSlice.actions.changeCouponOfOrder({ coupon: data }));
    } catch (error) {
      toast.error("Cupom inválido");
      setIsCouponLoading(false);
    }
    setIsCouponLoading(false);
  };

  const callToToast = ({ title, description }) => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">{title}</p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          <p className="text-sm text-slate-700 dark:text-slate-400">
            {description}
          </p>
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 2000 }
    );
  };

  const handleToFinishOrder = async () => {
    if (!isOpenWorkTime(catalog.operator_time)) {
      callToToast({
        description: "Estamos fechados no momento, tente novamente mais tarde.",
        title: "Estamos fechados!",
      });
      return;
    }

    if (!customer || !customer.name || !customer.phone || !customer.email) {
      document.getElementById("phone")?.focus();
      callToToast({
        description: "Preencha as informações de contato para continuar.",
        title: "Preencha sua informações!",
      });
      return;
    }
    setLoaddingSendOrder(true);
    try {
      const { data } = await httpClient({
        url: `/public/mount-catalogo/order`,
        method: "POST",
        data: {
          catalog: window.location.pathname.split("/")[1],
          order: cart,
          customer: customer,
        },
      });
      if (data.customer) {
        dispatch(customerSlice.actions.setCustomer(data.customer));
      }
      if (
        catalog.checkout &&
        catalog.checkout_type === "cart_to_whats" &&
        catalog.redirection_whats_number.length > 9
      ) {
        window.open(
          `https://api.whatsapp.com/send?phone=${
            catalog.redirection_whats_number
          }&text=${makeResumeCartInText(cart)}`,
          "_blank"
        );
      }
      setLoaddingSendOrder(false);
      dispatch(cartSlice.actions.newOrder());
    } catch (error) {}
    setLoaddingSendOrder(false);
  };

  const renderProduct = (item: ProductToOrder, index: number) => {
    const { photo, name } = item;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={photo}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">{name}</h3>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    value={item.quantity}
                    onChange={(e) => {
                      const value = +e.target.value;
                      if (item.quantity > value) {
                        dispatch(
                          cartSlice.actions.decrementProductQuantity({
                            product: item,
                          })
                        );
                      } else {
                        dispatch(
                          cartSlice.actions.incrementProductQuantity({
                            product: item,
                          })
                        );
                      }
                    }}
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={item.finish_value}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={item.finish_value} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber
                defaultValue={item.quantity}
                max={item.stock}
                onChange={(value) => {
                  if (item.quantity > value) {
                    dispatch(
                      cartSlice.actions.decrementProductQuantity({
                        product: item,
                      })
                    );
                  } else {
                    dispatch(
                      cartSlice.actions.incrementProductQuantity({
                        product: item,
                      })
                    );
                  }
                }}
                className="relative z-10"
              />
            </div>

            <Popconfirm
              title="Deseja realmente remover este produto?"
              onConfirm={() => {
                dispatch(
                  cartSlice.actions.removeProductFromOrder({ product: item })
                );
              }}
              okText="Sim"
              cancelText="Não"
            >
              <a
                href="##"
                className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
              >
                <span>Remove</span>
              </a>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  };

  const renderLeft = () => {
    return (
      <div className="space-y-8">
        <div id="ContactInfo" className="scroll-mt-24">
          <ContactInfo
            isActive={tabActive === "ContactInfo"}
            setTabActive={setTabActive}
            onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
            onCloseActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
          />
        </div>

        {catalog.delivery_config.active_delivery && (
          <div id="ShippingAddress" className="scroll-mt-24">
            <ShippingAddress
              setTabActive={setTabActive}
              isActive={tabActive === "ShippingAddress"}
              onOpenActive={() => {
                setTabActive("ShippingAddress");
                handleScrollToEl("ShippingAddress");
              }}
              onCloseActive={() => {
                setTabActive("PaymentMethod");
                handleScrollToEl("PaymentMethod");
              }}
            />
          </div>
        )}

        <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Finalizar</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-5 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Finalizar compra
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link
              to={`/${window.location.pathname.split("/")[1]}`}
              className=""
            >
              Voltar
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link
              to={`/${window.location.pathname.split("/")[1]}/cart`}
              className=""
            >
              Carrinho
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Finalizar</span>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold">Resumo</h3>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
              {cart.products.map(renderProduct)}
            </div>

            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
              {!cart.coupon && (
                <div>
                  <Label className="text-sm">Cupom de desconto</Label>
                  <div className="flex mt-1.5">
                    <Input
                      sizeClass="h-10 px-4 py-3"
                      className="flex-1"
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCode(e.target.value.toUpperCase().trim());
                      }}
                    />
                    <Button
                      loading={isCouponLoading}
                      onClick={handleApplyCoupon}
                      className="text-neutral-700 h-10 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors"
                    >
                      Aplicar
                    </Button>
                  </div>
                </div>
              )}

              {cart.coupon && (
                <div className="mt-4 flex justify-between py-2.5">
                  <span>Cupom de desconto</span>
                  <div className="flex items-center">
                    <Tag>{cart.coupon.code}</Tag>
                    <Popconfirm
                      title="Deseja realmente remover este cupom?"
                      onConfirm={() => {
                        dispatch(cartSlice.actions.removeCounponOfOrder());
                      }}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <a
                        href="##"
                        className="relative z-10 flex items-center   font-medium text-primary-6000 hover:text-primary-500 text-sm "
                      >
                        <span>Remover</span>
                      </a>
                    </Popconfirm>
                  </div>
                </div>
              )}

              <div className="mt-4 flex justify-between py-2.5">
                <span>Subtotal</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {currencyFormatter(cart.value)}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Entrega</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {currencyFormatter(cart.shipping)}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Desconto Cupom</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {currencyFormatter(cart.coupon_value_discount)}
                </span>
              </div>
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                <span>Valor final</span>
                <span>{currencyFormatter(cart.finish_value)}</span>
              </div>
            </div>

            {cart.referrer_code && (
              <div className="mt-4 flex justify-between py-2.5">
                <span>Código de indicação</span>
                <div className="flex items-center">
                  <Tag>{cart.referrer_code}</Tag>
                  <Popconfirm
                    title="Deseja realmente remover o código de indicação?"
                    onConfirm={() => {
                      dispatch(cartSlice.actions.removeReferrerCode());
                    }}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a
                      href="##"
                      className="relative z-10 flex items-center   font-medium text-primary-6000 hover:text-primary-500 text-sm "
                    >
                      <span>Remover</span>
                    </a>
                  </Popconfirm>
                </div>
              </div>
            )}

            {(!customer?._id || (customer._id && customer.is_client_new)) &&
              !cart.referrer_code &&
              programPoints?.activateProgram &&
              programPoints.active_referralPointsPerRealSpent && (
                <div>
                  <br />
                  <Label className="text-sm">Código de indicação</Label>
                  <div className="flex mt-1.5">
                    <Input
                      sizeClass="h-10 px-4 py-3"
                      className="flex-1"
                      value={referralCode}
                      onChange={(e) => {
                        setReferralCode(e.target.value.toUpperCase().trim());
                      }}
                    />
                    <Button
                      loading={isReferralCodeLoading}
                      onClick={hadnleApplyReferralCode}
                      className="text-neutral-700 h-10 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors"
                    >
                      Aplicar
                    </Button>
                  </div>
                </div>
              )}
            <ButtonPrimary
              loading={loaddingSendOrder}
              className="mt-8 w-full"
              onClick={handleToFinishOrder}
            >
              Enviar pedido
            </ButtonPrimary>
            <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
              <p className="block relative pl-5">
                <svg
                  className="w-4 h-4 absolute -left-1 top-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8V13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9945 16H12.0035"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Pode haver cancelamentos aguarde o {` `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  estabelecimento
                </a>
                {` `} aprovar seu pedido
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;
