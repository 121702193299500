import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { catalogo } from "./catalogo/reducer";
import { cartSlice } from "./cart/reducer";
import { customerSlice } from "./customer/reducer";
import { announcementsSlice } from "./announcements/recuder";

export const store = configureStore({
  reducer: {
    catalogo: catalogo.reducer,
    cart: cartSlice.reducer,
    customer: customerSlice.reducer,
    announcements: announcementsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch: () => AppDispatch = useDispatch;
