import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCatalogoDataBuilder } from "./thunk/getCatalogoData.thunk";
import { RootState } from "store";

export interface Root {
  catalogo: Catalogo;
  productCategoryCatalogo: ProductCategoryCatalogo[];
  announcements: AnnouncementsData[];
  programPoints: ProgramPoints | null;
}

export interface ProgramPoints {
  _id: string;
  menu: string;
  activateProgram: boolean;
  active_pointsPerRealSpent: boolean;
  active_initialPoints: boolean;
  active_referralPointsPerRealSpent: boolean;
  active_birthdayPointsPerRealSpent: boolean;
  active_purchaseGoal: boolean;
  pointsPerRealSpent: number;
  initialPoints: number;
  referralPointsPerRealSpent: number;
  birthdayPointsPerRealSpent: number;
  purchaseGoalPeriod: number;
  purchaseGoalAmount: number;
  purchaseGoalPointsPerRealSpent: number;
  push_multiplePoints: number;
  push_costPerPointMultiple: number;
  push_limitPointsPerPurchase: number;
  push_validityPoints: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AnnouncementsData {
  _id: string;
  title: string;
  menus: string[];
  icon_type: string;
  type: string;
  active: boolean;
  type_view: string;
  description: string;
  count_clients: number;
  btns: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface IGallery {
  catalog: Catalog;
  webhook?: string;
}

export interface Catalog {
  _id: string;
  name: string;
  description?: string;
  slug_endpoint: string;
  slug: string;
  photo: string;
}
export interface DeliveryConfig {
  active_delivery: boolean;
  ufs: string[];
  values_default: ValuesDefault[];
}

export interface ValuesDefault {
  city: string;
  uf: string;
  value: number;
  bairros?: Bairro[];
  other_neighborhoods?: boolean;
}

export interface Bairro {
  name: string;
  value: number;
}

export interface OperationTime {
  index: number;
  dia: string;
  active: boolean;
  turno: Turno[];
}

export interface Turno {
  inicio: string;
  fim: string;
}

export interface Catalogo {
  type_view: "catalog" | "gallery";
  gallery: IGallery[];
  script_to_catalog: any;
  _id: string;
  userCreated: string;
  name: string;
  slug_endpoint: string;
  slug: string;
  email: string;
  enable_days: string[];
  address: string;
  phone_number: string;
  pin_products: any[];
  __v: number;
  created: string;
  photo: string;
  updated: string;
  enable_hours_end: string;
  enable_hours_init: string;
  description: string;
  thumbnail: string;
  card_product_layout: number;
  color_primary: string;
  color_secondary: string;
  menu_layout: number;
  checkout: boolean;
  checkout_type: string;
  payment: boolean;
  payment_types: string[];
  redirection_whats: boolean;
  redirection_whats_number: string;
  tracing_events: any[];
  webhook_tracing: string;
  links_footer: boolean;
  delivery_config: DeliveryConfig;
  links_footer_value: LinksFooterValue[];
  social_media: boolean;
  social_media_value: SocialMediaValue[];
  operator_time: OperationTime[];
}

export interface LinksFooterValue {
  id: number;
  title: string;
  link?: string;
  expanded: boolean;
  children: Children[];
}

export interface Children {
  id: number;
  title: string;
  link?: string;
}

export interface SocialMediaValue {
  key: string;
  link: string;
  enable: boolean;
}

export interface ProductCategoryCatalogo {
  _id: string;
  name: string;
  secondary_name?: string;
  isFixed: boolean;
  order_category: number;
  type_category: string;
  tracing_events: string[];
  webhook_tracing: string;
  menu_id: string;
  products: Product[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  products_props: PorductsProp[];
}

export interface Product {
  _id: string;
  menus: string[];
  name: string;
  description: string;
  price: number;
  photo: string;
  enable_days: any[];
  created: string;
  updated: string;
  check_stock: string;
  low_stock_alert: number;
  __v: number;
  for_price?: number;
  complements: string[];
  stock: number;
  enable_hours_end?: string;
  enable_hours_init?: string;
}

export interface PorductsProp {
  product: string;
  tracing_events?: any[];
  group: string;
  menu_id?: string;
  tag_product?: string;
  webhook_tracing?: string;
}

export interface InitialStateCatalogo {
  data: Root;
  loading: boolean;
  notFound: boolean;
  theme: "dark" | "light";
}

const initialState: InitialStateCatalogo = {
  data: {
    catalogo: null as any,
    productCategoryCatalogo: [],
    announcements: [],
    programPoints: null,
  },
  notFound: false,
  loading: true,
  theme: "light",
};

export const catalogo = createSlice({
  name: "catalogo",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<"dark" | "light">) => {
      state.theme = action.payload;
    },
  },
  extraReducers: (builder) => {
    getCatalogoDataBuilder(builder);
  },
});

export const selectFixedCategory = () =>
  createSelector(
    (state: RootState) => state.catalogo.data.productCategoryCatalogo,
    (orderData) => orderData.find((order) => order.isFixed)
  );

export const selectOnlyCatalogo = () =>
  createSelector(
    (state: RootState) => state.catalogo.data.catalogo,
    (catalogo) => catalogo
  );

export const selectTheme = () =>
  createSelector(
    (state: RootState) => state.catalogo.theme,
    (theme) => theme
  );
