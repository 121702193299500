import React, { FC, useEffect, useId } from "react";
import { Helmet } from "react-helmet-async";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Link, Element } from "react-scroll";
import {
  PorductsProp,
  Product,
  ProductCategoryCatalogo,
} from "store/catalogo/reducer";
import { productValidToView } from "utils/productValidToView";

export interface PageCollectionProps {
  className?: string;
}

const SliderCategories = ({
  categories,
}: {
  categories: ProductCardProps[];
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const scrollToItem = (item: string) => {
    const element = document.getElementById(item);

    if (!element) return;

    element.scrollIntoView({ behavior: "smooth", block: "start" });
    window.document
      .getElementById(item)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div
      className={`flex items-center text-sm font-normal text-gray-500 dark:text-gray-400 ${UNIQUE_CLASS}`}
    >
      {/* CATEGORIES */}
      <ul className="category-link-slider">
        {categories.map((item, index) => (
          <li key={index} className={` slider-item `}>
            <Link
              className="category-link"
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              id={item.category._id}
              onSetActive={(to, element) => {
                try {
                  scrollToItem(to);
                } catch (error) {
                  console.log(error);
                }
              }}
              to={item.category._id}
              key={index}
            >
              {item.category.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export interface ProductsMounts extends Product {
  props: PorductsProp[];
  propsCategory: {
    tracing_events: string[];
    webhook_tracing: string;
  };
}

interface ProductCardProps {
  category: ProductCategoryCatalogo;
  products: Array<ProductsMounts>;
}

const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
  const { catalogo, productCategoryCatalogo, programPoints } = useSelector(
    (state: RootState) => state.catalogo.data
  );
  const customer = useSelector((state: RootState) => state.customer.customer);
  const [categories, setCategories] = React.useState<Array<ProductCardProps>>(
    []
  );

  useEffect(() => {
    const reduceCategories = productCategoryCatalogo.filter(
      (c) => c.products.length > 0 && !c.isFixed
    );

    const categoriesWithProducts = reduceCategories.map((category) => {
      return {
        category,
        products: category.products.map((product) => {
          return {
            ...product,
            propsCategory: {
              tracing_events: category.tracing_events,
              webhook_tracing: category.webhook_tracing,
            },
            props: category.products_props.filter(
              (p) => p.product === product._id
            ),
          };
        }),
      };
    }) as unknown as Array<ProductCardProps>;

    setCategories(categoriesWithProducts);
  }, [productCategoryCatalogo]);

  return (
    <div
      className={`nc-PageCollection relative overflow-hidden ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>{catalogo?.name}</title>
      </Helmet>
      <div className="top-0   hidden lg:block">
        <img
          className="w-full h-56 object-none "
          src={catalogo?.thumbnail || ""}
          alt={catalogo?.name}
        />
      </div>
      <div className="mt-16 lg:mt-9 ">
        <div className="">
          <DiscoverMoreSlider />
        </div>
      </div>
      <div className="container py-10 lg:pb-28 lg:pt-1 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div
            id="sticky-banner"
            tabIndex={-1}
            className="container-scroll-slider sm:hidden fixed max-h-12 top-20 start-0 z-50 flex justify-between w-full p-4 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
          >
            <div className="flex items-center mx-auto">
              <SliderCategories categories={categories} />
            </div>
          </div>
          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* LOOP ITEMS */}
            {categories.map((item, index) => (
              <Element
                name={item.category._id}
                key={index}
                className="element-main py-100"
              >
                <div className="mb-10 ">
                  <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-10 ">
                    {item.category.name}
                  </h2>
                  <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10">
                    {item.products
                      .filter((p) => productValidToView(p))
                      .map((product, index) => {
                        return <ProductCard data={product} key={index} />;
                      })}
                  </div>
                </div>
              </Element>
            ))}
          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderCollections />
        <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {programPoints && programPoints.activateProgram && (
          <>{customer && customer?._id ? null : <SectionPromo1 />}</>
        )}
      </div>
    </div>
  );
};

export default PageCollection;
