import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import explore1Svg from "images/collections/explore1.svg";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { IGallery } from "store/catalogo/reducer";
import { fireWebhook } from "services/fireWebHook";
import { Typography } from "antd";
const { Paragraph } = Typography;

export interface CardCategory4Props {
  className?: string;
  featuredImage?: string;
  bgSVG?: string;
  name: string;
  desc: string;
  color?: string;
  payload: IGallery;
}

const CardCategory4: FC<CardCategory4Props> = ({
  className = "",
  featuredImage = ".",
  bgSVG = explore1Svg,
  name,
  desc,
  color = "bg-rose-50",
  payload,
}) => {
  return (
    <div
      onClick={async () => {
        if (payload.webhook) {
          await fireWebhook(payload.webhook);
        }
      }}
      className={`nc-CardCategory4 relative w-full aspect-w-12 aspect-h-11 h-0 rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 group hover:nc-shadow-lg transition-shadow ${className}`}
      data-nc-id="CardCategory4"
    >
      <div>
        <div className="absolute bottom-0 right-0 max-w-[280px] opacity-80">
          <img src={bgSVG} alt="" />
        </div>

        <div className="absolute inset-5 sm:inset-8 flex flex-col justify-between">
          <div className="flex justify-between items-center">
            <NcImage
              src={featuredImage}
              containerClassName={`w-20 h-20 rounded-full overflow-hidden z-0 ${color}`}
            />
            <span className="text-xs text-slate-700 dark:text-neutral-300 font-medium">
              {payload.catalog.name}
            </span>
          </div>

          <div className="">
            <span
              className={`block mb-2 text-sm text-slate-500 dark:text-slate-400  sm:block sm:max-w-[50ch] max-w-[25ch]`}
            >
              <Paragraph ellipsis={{ rows: 3 }}>{desc}</Paragraph>
            </span>
            <h2 className={`text-1xl sm:text-3xl font-semibold`}>{name}</h2>
          </div>

          <a
            href={`${payload.catalog.slug_endpoint}${payload.catalog.slug}`}
            target="_blank"
            rel="noreferrer"
            className="flex items-center text-sm font-medium group-hover:text-primary-500 transition-colors"
          >
            <span>Acessar</span>
            <ArrowRightIcon className="w-4 h-4 ml-2.5" />
          </a>
        </div>
      </div>

      <a
        href={`${payload.catalog.slug_endpoint}${payload.catalog.slug}`}
        target="_blank"
        rel="noreferrer"
        className="absolute inset-0"
      >
        <i></i>
      </a>
    </div>
  );
};

export default CardCategory4;
