import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MyOrders from "containers/MyOrders/MyOrders";
import Order from "containers/Order/Order";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";

export const pages = [
  { path: "/", component: Page404 },
  { path: "/:slug", component: PageCollection },
  { path: "/:slug/cart", component: CartPage },
  { path: "/:slug/checkout", component: CheckoutPage },
  { path: "/:slug/programa-de-pontos", component: BlogSingle },
  { path: "/:slug/meus-pedidos", component: MyOrders },
  { path: "/:slug/meus-pedidos/:id", component: Order },
  // { path: "/home2", component: PageHome2 },
  // { path: "/home3", component: PageHome3 },
  // //
  // { path: "/home-header-2", component: PageHome },
  // { path: "/product-detail", component: ProductDetailPage },
  // { path: "/product-detail-2", component: ProductDetailPage2 },
  // //
  // { path: "/page-collection-2", component: PageCollection2 },
  // { path: "/page-collection", component: PageCollection },
  // { path: "/page-search", component: PageSearch },
  // //
  // { path: "/account", component: AccountPage },
  // { path: "/account-savelists", component: AccountSavelists },
  // { path: "/account-change-password", component: AccountPass },
  // { path: "/account-billing", component: AccountBilling },
  // { path: "/account-my-order", component: AccountOrder },
  // //
  // //
  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },
  // //
  // { path: "/contact", component: PageContact },
  // { path: "/about", component: PageAbout },
  // { path: "/signup", component: PageSignUp },
  // { path: "/login", component: PageLogin },
  // { path: "/subscription", component: PageSubcription },
];

const MyRoutes = () => {
  const { notFound, data } = useSelector((state: RootState) => state.catalogo);

  if (notFound) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Page404 />} path={"*"} />
        </Routes>
      </BrowserRouter>
    );
  }
  // nc-BackgroundSection absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 bg-neutral-100/70 dark:bg-black/20

  if (data?.catalogo?.type_view === "gallery") {
    return (
      <BrowserRouter>
        <Toaster />
        <ScrollToTop />
        <Routes>
          <Route
            element={
              <div
                className="container py-10 lg:py-10 relative "
                style={{ minHeight: "80vh" }}
              >
                <div className="relative py-10 lg:py-10 p-4 sm:p-10 nc-BackgroundSection inset-y-0 left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 bg-neutral-100/70 dark:bg-black/20 ">
                  <SectionGridMoreExplore />
                </div>
              </div>
            }
            path={"/:slug"}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} path={"not_found"} />
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
