export function gerarNrPedido(length = 7) {
  const numeros = "0123456789";
  let nrPedido = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * numeros.length);
    nrPedido += numeros[randomIndex];
  }

  return nrPedido;
}
