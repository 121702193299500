import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialStateCatalogo } from "../reducer";
import { httpClient } from "services/api";
import { announcementsSlice } from "store/announcements/recuder";

export const getCatalogoData = createAsyncThunk(
  "getCatalogoData/async",
  async ({ slug }: { slug: string }, { dispatch }) => {
    const { data } = await httpClient({
      url: `/public/mount-catalogo/${slug}`,
      method: "GET",
    });

    if (data && data.announcements) {
      dispatch(announcementsSlice.actions.setAnnouncements(data.announcements));
    }

    return data;
  }
);

export const getCatalogoDataBuilder = (
  builder: ActionReducerMapBuilder<InitialStateCatalogo>
) => {
  builder
    .addCase(getCatalogoData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.theme = action.payload.catalogo?.theme || "light";
    })
    .addCase(getCatalogoData.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCatalogoData.rejected, (state) => {
      state.loading = false;
      state.notFound = true;
    });
};
