import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet-async";
import rightImgDemo from "images/rightLargeImg.png";
import { _getImgRd, _getPersonNameRd, _getTitleRd } from "contains/fakeData";

import Tag from "shared/Tag/Tag";
import { useSelector } from "react-redux";
import { RootState } from "store";

const BlogSingle = () => {
  const { catalogo, programPoints } = useSelector(
    (state: RootState) => state.catalogo.data
  );

  if (!catalogo || !programPoints) return null;

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Programa de Pontos" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Entenda as Regras do Programa de Pontos do {catalogo?.name}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            No {catalogo?.name}, nosso objetivo é oferecer a melhor experiência
            para nossos clientes. Um dos nossos principais recursos é o Programa
            de Pontos de Fidelidade, que recompensa os clientes por suas compras
            e interações com a plataforma. Abaixo, explicamos detalhadamente
            como funcionam as regras do programa de pontos.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                imgUrl={catalogo?.photo}
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="##">
                    {catalogo?.name}
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {new Date().toLocaleDateString()}
                  </span>
                  {/* <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span> */}
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-1.5 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert"
      >
        {programPoints.active_pointsPerRealSpent && (
          <div>
            <h4>Ganho de Pontos por Real Gasto</h4>
            <p>
              Os clientes ganham {programPoints.pointsPerRealSpent} pontos por
              cada real gasto.
            </p>
          </div>
        )}

        {programPoints.active_initialPoints && (
          <div>
            <h4>Pontos Iniciais</h4>
            <p>
              Novos clientes recebem {programPoints.initialPoints} pontos ao se
              inscreverem no programa de pontos.
            </p>
          </div>
        )}

        {programPoints.active_referralPointsPerRealSpent && (
          <div>
            <h4>Pontos por Indicação</h4>
            <p>
              Os clientes ganham {programPoints.referralPointsPerRealSpent}{" "}
              pontos por cada indicação bem-sucedida, assim que o cliente
              indicado realizar sua primeira compra.
            </p>
            <blockquote>
              <p>
                {`Seu código de indicação é o numero do seu telefone apenas com os números, exemplo: 11999999999`}
              </p>
            </blockquote>
          </div>
        )}

        {programPoints.active_birthdayPointsPerRealSpent && (
          <div>
            <h4>Pontos de Aniversário</h4>
            <p>
              Os clientes ganham {programPoints.birthdayPointsPerRealSpent}{" "}
              pontos por cada real gasto no dia do seu aniversário.
            </p>
          </div>
        )}

        {programPoints.active_purchaseGoal && (
          <div>
            <h4>Meta de Compras</h4>
            <p>
              O período para atingir a meta de compras é de{" "}
              {programPoints.purchaseGoalPeriod} dias.
            </p>
            <p>
              O valor mínimo de compras necessário para atingir a meta é de R$
              {programPoints.purchaseGoalAmount}.
            </p>
            <p>
              Os clientes ganham {programPoints.purchaseGoalPointsPerRealSpent}{" "}
              pontos de bônus ao atingirem a meta de compras estabelecida.
            </p>
          </div>
        )}

        <figure>
          <NcImage containerClassName="block " src={rightImgDemo} />
          <figcaption>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              Imagem ilustrativa
            </span>
          </figcaption>
        </figure>

        <h3>Regras de Uso</h3>

        <div>
          <h4>Uso dos Pontos</h4>
          <p>
            Os pontos são resgatados em múltiplos de{" "}
            {programPoints.push_multiplePoints}.
          </p>
          <p>
            O custo para cada múltiplo de ponto é de R$
            {programPoints.push_costPerPointMultiple}.
          </p>
          {programPoints.push_limitPointsPerPurchase > 0 && (
            <p>
              O limite máximo de resgate por compra é de{" "}
              {programPoints.push_limitPointsPerPurchase} pontos.
            </p>
          )}

          {/* <p>
            Os pontos acumulados têm uma validade de{" "}
            {programPoints.push_validityPoints} dias a partir da data de
            acúmulo.
          </p> */}
        </div>

        <p>
          Estas são as regras do nosso Programa de Pontos de Fidelidade.
          Esperamos que aproveite ao máximo essa funcionalidade! Para mais
          informações, entre em contato com o suporte.
        </p>
        <br />
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap space-x-2">
        <Tag />
        <Tag />
        <Tag />
        <Tag />
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="##">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="##">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (_: any, index: number) => {
    return (
      <div
        key={index}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={"/blog-single"} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={_getImgRd()}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{_getTitleRd()}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {_getPersonNameRd()}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">May 20, 2021</span>
          </div>
        </div>
        <Link to={"/blog-single"} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>{catalogo?.name} || Regras de pontos</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={catalogo?.thumbnail}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
    </div>
  );
};

export default BlogSingle;
