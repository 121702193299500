import { EyeIcon } from "@heroicons/react/24/outline";
import Prices from "components/Prices";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { httpClient } from "services/api";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { RootState } from "store";
import { IOrder } from "store/cart/reducer";

const returnStatusJSX = (status: string) => {
  switch (status) {
    case "completed":
      return (
        <span className="text-green-500 dark:text-green-400"> Concluído</span>
      );
    case "preparing":
      return (
        <span className="text-blue-500 dark:text-blue-400">
          {" "}
          Preparando
        </span>
      );
    case "ready":
      return (
        <span className="text-green-500 dark:text-green-400"> Pronto</span>
      );
    case "canceled":
      return <span className="text-red-500 dark:text-red-400"> Cancelado</span>;
    case "delivery":
      return (
        <span className="text-blue-500 dark:text-blue-400">
          Saiu para entrega
        </span>
      );
    default:
      return (
        <span className="text-yellow-500 dark:text-yellow-400"> Pendente</span>
      );
  }
};

const MyOrders = () => {
  const customer = useSelector((state: RootState) => state.customer.customer);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const navigate = useNavigate();
  const { slug } = useParams();
  const searchOrder = useCallback(async () => {
    try {
      const { data } = await httpClient({
        url: `/orders-catalog/${customer._id}`,
        method: "GET",
      });
      setOrders(data || []);
    } catch (error) {
      console.error(error);
    }
  }, [customer._id]);

  useEffect(() => {
    searchOrder();
  }, [searchOrder]);

  const renderOrders = (item: IOrder, index: number) => {
    return (
      <div
        key={index}
        className="relative flex py-8 flex-col first:pt-0 last:pb-0"
      >
        <div className=" flex justify-between ">
          <div>
            N° #{item.nr}
            {" - "}
            <span>
              <strong>
                [{" "}
                {item.products.length +
                  ` produto${item.products.length > 1 ? "s" : ""}`}{" "}
                ]
              </strong>
            </span>
            {" - "}
            <span>{returnStatusJSX(item.status || "")}</span>
          </div>
          <Prices
            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
            pricePromo={
              item.value > item.finish_value ? item.finish_value : undefined
            }
            price={item.value}
          />
        </div>
        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="w-full">
              <ul>
                {item.products.map((product, index) => (
                  <li key={index} className="flex justify-between">
                    <span>
                      {product.name} [x {product.quantity}]
                      <ul className="ml-5">
                        {product.complements.length
                          ? product.complements.map((variation, index) => (
                              <li key={index}>
                                <strong>{variation.name}</strong>:{" "}
                                {variation.itens
                                  .map((item) => `${item.name}`)
                                  .join(", ")}
                              </li>
                            ))
                          : null}
                      </ul>
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            {!["completed", "canceled"].includes(item?.status || "") && (
              <ButtonPrimary
                className="text-sm ml-2"
                onClick={() => navigate(`/${slug}/meus-pedidos/${item._id}`)}
              >
                <EyeIcon className="w-5 h-5" />
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MyOrders">
      <Helmet>
        <title>Meus Pedidos Hoje</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-5 min-h-[636px]">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Meus Pedidos Hoje
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link
              to={`/${window.location.pathname.split("/")[1]}`}
              className=""
            >
              Voltar
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">carrinho</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full  divide-y divide-slate-200 dark:divide-slate-700 ">
            {orders.map(renderOrders)}
          </div>
        </div>
      </main>
    </div>
  );
};

export default MyOrders;
