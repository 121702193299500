import { IOrder } from "store/cart/reducer";
import { currencyFormatter } from "./currencyFormatter";

export const makeResumeCartInText = (data: IOrder) => {
  let resume = `N° Pedido: ${data.nr}\n`;
  resume += `Data: ${new Date().toLocaleString()}\n`;

  data.products.forEach((item) => {
    resume += `${item.name} - ${item.quantity} x ${currencyFormatter(
      item.price
    )}\n`;
  });

  resume += `Total: ${currencyFormatter(data.finish_value)}`;

  return resume;
};
