import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";

import { httpClient } from "services/api";
import { RootState } from "store";
import { announcementsSlice } from "store/announcements/recuder";
import axios from "axios";
import { fireWebhook } from "services/fireWebHook";

export interface AnnouncementsData {
  _id: string;
  type: "notification" | "modal";
  menus: string[];
  title: string;
  description: string;
  image?: string;
  active: boolean;
  type_view: "persistent" | "unique";
  icon_type: string;
  btns: Btn[];
  test: boolean;
}

export interface Btn {
  action_type: string;
  text: string;
  link: string;
  webhook_tracing: string;
}

export const Buttons = ({ btns }: { btns: Btn[] }) => {
  return (
    <>
      {btns?.map((btn, index) => {
        if (btn.action_type === "open_url") {
          return (
            <Button
              key={index}
              type="dashed"
              size="small"
              href={btn?.link}
              target="_blank"
              onClick={() => fireWebhook(btn?.webhook_tracing)}
            >
              {btn?.text}
            </Button>
          );
        }
        if (btn.action_type === "copy_value") {
          return (
            <Button
              key={index}
              type="dashed"
              size="small"
              onClick={() => {
                message.success("Copiado para a área de transferência");
                navigator.clipboard.writeText(btn?.link);
                fireWebhook(btn?.webhook_tracing, { teste: "teste" });
              }}
            >
              {btn?.text}
            </Button>
          );
        }
      })}
    </>
  );
};

export const FireAnnouncement = () => {
  const fireAnnouncement = useSelector(
    (state: RootState) => state.announcements.fireAnnouncementData
  );
  const dispatch = useDispatch();
  const [process, setProcess] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [modalProps, setModalProps] = useState<AnnouncementsData | null>(null);

  useEffect(() => {
    if (process) {
      return;
    }
    console.log("Iniciando anuncio");
    const announcement = fireAnnouncement[0];
    if (announcement) {
      // do something
      setProcess(true);

      const announcements = JSON.parse(
        localStorage.getItem("announcements") || "[]"
      ) as Array<string>;
      if (announcement.type_view === "unique") {
        if (announcements.includes(announcement._id)) {
          dispatch(
            announcementsSlice.actions.removeFireAnnouncement(announcement._id)
          );
          setProcess(false);
          return;
        } else {
          announcements.push(announcement._id);
          localStorage.setItem("announcements", JSON.stringify(announcements));
        }
      }

      setViewModal(announcement.type === "modal");
      setModalProps(announcement);

      if (announcement.type === "notification") {
        toast[announcement.icon_type](
          <>
            <div className="d-flex flex-column">
              <strong>{announcement.title}</strong>
              <div>
                <p>{announcement.description}</p>
                <br />
                <div>
                  <Buttons btns={announcement?.btns || []} />
                </div>
              </div>
            </div>
          </>,
          {
            position: "top-right",
            hideProgressBar: false,
            autoClose: false,
            pauseOnHover: true,
            progress: undefined,
            type: announcement.icon_type as any,
          }
        );
      }

      dispatch(
        announcementsSlice.actions.removeFireAnnouncement(announcement._id)
      );
      setProcess(false);

      const markView = async () => {
        try {
          await httpClient({
            url: `/public/mount-catalogo/announcement/${announcement._id}/view`,
            method: "put",
          });
        } catch (error) {
          console.error("Erro ao marcar anuncio como visto", error);
        }
      };
      if (!announcement?.mark_view) {
        markView();
      }
      console.log(`Anuncio finalizado: ${announcement._id}`);
    }
  }, [dispatch, fireAnnouncement, process]);

  return (
    <div>
      {viewModal && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <SweetAlert
          custom
          showCancel
          showCloseButton
          inputType={modalProps?.icon_type}
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          customIcon={modalProps?.image}
          title={modalProps?.title}
          onConfirm={(e) => console.log(e)}
          onCancel={() => setViewModal(false)}
          customButtons={
            <>
              <Buttons btns={modalProps?.btns || []} />
            </>
          }
        >
          {modalProps?.description}
        </SweetAlert>
      )}
    </div>
  );
};
