import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
import twitter from "images/socials/twitter.svg";
import telegram from "images/socials/telegram.svg";
import youtube from "images/socials/youtube.svg";
import instagram from "images/socials/instagram.svg";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { SocialMediaValue } from "store/catalogo/reducer";

export interface SocialsList1Props {
  className?: string;
}

export const socials: SocialType[] = [
  { name: "Facebook", icon: facebook, href: "#" },
  { name: "Youtube", icon: youtube, href: "#" },
  { name: "Telegram", icon: telegram, href: "#" },
  { name: "Twitter", icon: twitter, href: "#" },
  { name: "Instagram", icon: instagram, href: "#" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-3" }) => {
  const catalogo = useSelector(
    (state: RootState) => state.catalogo.data.catalogo
  );

  const renderItem = (item: SocialMediaValue, index: number) => {
    return (
      <a
        href={item.link}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="flex-shrink-0 w-5 ">
          <img src={socials.find((f) => f.name === item.key)?.icon} alt="" />
        </div>
        <span className="hidden lg:block text-sm">{item.key}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {catalogo?.social_media &&
        catalogo?.social_media_value
          .filter((s) => s?.link?.length)
          .map(renderItem)}
    </div>
  );
};

export default SocialsList1;
