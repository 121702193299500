import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { LinksFooterValue } from "store/catalogo/reducer";

const Footer: React.FC = () => {
  const catalogo = useSelector(
    (state: RootState) => state.catalogo.data.catalogo
  );

  const renderWidgetMenuItem = (menu: LinksFooterValue, index: number) => {
    return (
      <div key={index} className="text-sm">
        <a href={menu?.link}>
          <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
            {menu.title}
          </h2>
        </a>
        <ul className="mt-5 space-y-4">
          {menu.children.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="hidden md:block nc-Footer relative py-10    border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>
        {catalogo?.links_footer &&
          catalogo?.links_footer_value.map(renderWidgetMenuItem)}

        {/* Show endereco details of catalogo*/}
        <div className="text-sm">
          <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
            Endereço
          </h2>
          <p className="mt-5 text-neutral-6000 dark:text-neutral-300">
            {catalogo?.address}
          </p>
          <p className="mt-5 text-neutral-6000 dark:text-neutral-300">
            <i className="fas fa-envelope"></i> {catalogo?.email} <br />
            <i className="fas fa-envelope"></i> {catalogo?.phone_number}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
