import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW2img from "images/HIW2img.png";
import HIW3img from "images/HIW3img.png";
import HIW4img from "images/HIW4img.png";
import delivery from "images/delivery.png";
import VectorImg from "images/VectorHIW.svg";
import { IOrder } from "store/cart/reducer";

export interface SectionHowItWorkProps {
  className?: string;
  order: IOrder;
  data?: (typeof DEMO_DATA)[0][];
}

const DEMO_DATA = [
  {
    id: 1,
    img: HIW3img,
    imgDark: HIW3img,
    status: "pending",
    title: "Pendente",
    desc: "Aguardando aprovação do estabelecimento",
  },
  {
    id: 2,
    img: HIW2img,
    imgDark: HIW2img,
    status: "preparing",
    title: "Preparando",
    desc: "Seu pedido está sendo preparado",
  },
  {
    id: 3,
    img: HIW2img,
    imgDark: HIW2img,
    status: "ready",
    title: "Pronto",
    desc: "Seu pedido está pronto para ser retirado",
  },
  {
    id: 4,
    img: delivery,
    imgDark: delivery,
    status: "delivery",
    title: "Entrega",
    desc: "Seu pedido está a caminho",
  },
  {
    id: 5,
    img: HIW4img,
    imgDark: HIW4img,
    status: "completed",
    title: "Finalizado",
    desc: "Seu pedido foi entregue para você",
  },
  {
    id: 6,
    img: HIW4img,
    imgDark: HIW4img,
    status: "Aguardando",
  },
];

const StepsOrder: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
  order,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <div className="relative grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-5"
          src={VectorImg}
          alt="vector"
        />
        {data
          .filter((steps) => {
            const stepCurrent = DEMO_DATA.find(
              (step) => step.status === order.status
            );
            if (!stepCurrent) return false;
            let statusToVisible: Array<number> = [];

            if (order.status === "pending") {
              statusToVisible = [1, 6];
            }

            if (order.status === "preparing") {
              statusToVisible = [1, 2, 6];
            }

            if (order.status === "delivery") {
              statusToVisible = [1, 3, 4, 6];
            }

            if (order.status === "ready") {
              statusToVisible = [1, 3, 6];
            }

            if (order.status === "completed") {
              statusToVisible = [5];
            }

            if (statusToVisible.includes(steps.id)) {
              return true;
            } else {
              return false;
            }
          })
          .map((item: (typeof DEMO_DATA)[number], index: number) => {
            if (item.id === 6) {
              return (
                <div
                  key={item.id}
                  className="flex justify-center items-center flex-col"
                >
                  <div className="p-4 text-slate-600 dark:text-slate-400 text-center rounded-lg animate-pulse">
                    Aguardando atualizações.
                  </div>
                </div>
              );
            }

            return (
              <div
                key={item.id}
                className="relative flex flex-col items-center max-w-xs mx-auto"
              >
                <NcImage
                  containerClassName="mb-4 sm:mb-10 max-w-[140px] mx-auto"
                  className="rounded-3xl"
                  src={item.img}
                />

                <div className="text-center mt-auto space-y-5">
                  <h3 className="text-base font-semibold">{item.title}</h3>
                  <span className="block text-slate-600 dark:text-slate-400 text-sm leading-6">
                    {item.desc}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default StepsOrder;
