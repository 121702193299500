import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Popconfirm } from "antd";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { RootState, useAppDispatch } from "store";
import { cartSlice, ProductToOrder } from "store/cart/reducer";
import { Product } from "store/catalogo/reducer";
import { currencyFormatter } from "utils/currencyFormatter";
import { ProductReturnStock } from "utils/productReturnStock";

const CartPage = () => {
  const cart = useSelector((state: RootState) => state.cart.order);
  const dispatch = useAppDispatch();
  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Sold Out</span>
      </div>
    );
  };

  const renderStatusInstock = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <CheckIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Em estoque</span>
      </div>
    );
  };

  const renderProduct = (item: ProductToOrder, index: number) => {
    const { photo, name } = item;

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={photo}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">{name}</h3>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    value={item.quantity}
                    onChange={(e) => {
                      const value = +e.target.value;
                      if (item.quantity > value) {
                        dispatch(
                          cartSlice.actions.decrementProductQuantity({
                            product: item,
                          })
                        );
                      } else {
                        if (
                          item.check_stock === "yes" &&
                          ProductReturnStock(item as any) > value
                        ) {
                          alert("Quantidade indisponível");
                          return;
                        }
                        dispatch(
                          cartSlice.actions.incrementProductQuantity({
                            product: item,
                          })
                        );
                      }
                    }}
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={item.finish_value}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                <NcInputNumber
                  defaultValue={item.quantity}
                  max={ProductReturnStock(item as any)}
                  onChange={(value) => {
                    if (item.quantity > value) {
                      dispatch(
                        cartSlice.actions.decrementProductQuantity({
                          product: item,
                        })
                      );
                    } else {
                      dispatch(
                        cartSlice.actions.incrementProductQuantity({
                          product: item,
                        })
                      );
                    }
                  }}
                  className="relative z-10"
                />
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={item.finish_value} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            {renderStatusInstock()}

            <Popconfirm
              title="Deseja realmente remover este produto?"
              onConfirm={() => {
                dispatch(
                  cartSlice.actions.removeProductFromOrder({ product: item })
                );
              }}
              okText="Sim"
              cancelText="Não"
            >
              <a
                href="##"
                className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
              >
                <span>Remove</span>
              </a>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CartPage">
      <Helmet>
        <title>Carrinho</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-5">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Carrinho de compra
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link
              to={`/${window.location.pathname.split("/")[1]}`}
              className=""
            >
              Voltar
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">carrinho</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
            {cart.products.map(renderProduct)}
          </div>
          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="sticky top-28">
              <h3 className="text-lg font-semibold ">Resumo do pedido</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex justify-between pb-4">
                  <span>Subtotal</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {currencyFormatter(cart.value)}
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Entrega</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    R$ 0.00
                  </span>
                </div>

                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Pedido total</span>
                  <span>{currencyFormatter(cart.finish_value)}</span>
                </div>
              </div>
              <ButtonPrimary
                href={`/${window.location.pathname.split("/")[1]}/checkout`}
                className="mt-8 w-full"
              >
                Finalizar
              </ButtonPrimary>
              <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                <p className="block relative pl-5">
                  <svg
                    className="w-4 h-4 absolute -left-1 top-0.5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 8V13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.9945 16H12.0035"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Se voce possuir um{` `}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="##"
                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                  >
                    CUPOM
                  </a>
                  {` `} poderá adicionar no checkout
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CartPage;
