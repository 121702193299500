import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { gerarNrPedido } from "utils/makeNumberOrder";

export interface AnnouncementsData {
  _id: string;
  type: "notification" | "modal";
  menus: string[];
  title: string;
  description: string;
  image?: string;
  mark_view?: boolean;
  active: boolean;
  type_view: "persistent" | "unique";
  icon_type: string;
  btns: Btn[];
  test: boolean;
}

export interface Btn {
  action_type: string;
  text: string;
  link: string;
  webhook_tracing: string;
}

export interface IAnnouncementsInitialState {
  loading: boolean;
  fireAnnouncementData: AnnouncementsData[];
}

const initialState: IAnnouncementsInitialState = {
  loading: false,
  fireAnnouncementData: [],
};

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState: initialState,
  reducers: {
    setAnnouncements: (state, action: PayloadAction<AnnouncementsData[]>) => {
      state.fireAnnouncementData = action.payload;
    },
    addFireAnnouncement: (state, action: PayloadAction<AnnouncementsData>) => {
      state.fireAnnouncementData.push({
        ...action.payload,
        mark_view: false,
      });
    },
    removeFireAnnouncement: (state, action: PayloadAction<string>) => {
      state.fireAnnouncementData = state.fireAnnouncementData.filter(
        (item) => item._id !== action.payload
      );
    },
  },
});
