import { SocialType } from "shared/SocialsShare/SocialsShare";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { socials as SocialList } from "shared/SocialsList1/SocialsList1";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
  socials = SocialList,
}) => {
  const catalogo = useSelector(
    (state: RootState) => state.catalogo.data.catalogo
  );

  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {catalogo?.social_media &&
        catalogo?.social_media_value
          .filter((s) => s?.link?.length)
          .map((item, i) => (
            <a
              key={i}
              className={`${itemClass}`}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              title={item.key}
            >
              <img
                src={socials.find((f) => f.name === item.key)?.icon}
                alt=""
              />
            </a>
          ))}
    </nav>
  );
};

export default SocialsList;
