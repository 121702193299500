import { Product } from "store/catalogo/reducer";

export const productValidToView = (product: Product): Boolean => {
  try {
    if (product.check_stock === "yes" && product.stock <= 0) {
      return false;
    }

    if (product.enable_days.length > 0) {
      const today = new Date();
      const day = today.getDay();
      const enableDay = product.enable_days.find((d) => +d === day);
      if (!enableDay) {
        return false;
      }
    }

    if (product.enable_hours_init && product.enable_hours_end) {
      const today = new Date();
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const enableHourInit = product.enable_hours_init.split(":");
      const enableHourEnd = product.enable_hours_end.split(":");
      const hourInit = parseInt(enableHourInit[0]);
      const minutesInit = parseInt(enableHourInit[1]);
      const hourEnd = parseInt(enableHourEnd[0]);
      const minutesEnd = parseInt(enableHourEnd[1]);

      if (hours < hourInit || hours > hourEnd) {
        return false;
      }

      if (hours === hourInit && minutes < minutesInit) {
        return false;
      }

      if (hours === hourEnd && minutes > minutesEnd) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
};
