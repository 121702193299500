import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { RootState, useAppDispatch } from "store";
import { announcementsSlice } from "store/announcements/recuder";

// create selector to get catalog from state
const getCatalog = createSelector(
  (state: RootState) => state.catalogo.data.catalogo,
  (meuCatalog) => meuCatalog
);
let socketIsReady = false;

export const WebSocketRootClientHandle = () => {
  const catalogo = useSelector(getCatalog);
  const distpatch = useAppDispatch();

  useEffect(() => {
    if (!catalogo || socketIsReady) {
      return;
    }
    const socket = io(
      process.env.REACT_APP_URL_BACKEND ||
        "https://kirango-api-production.up.railway.app"
    );

    if (catalogo) {
      if (socket.connected) {
        return;
      }

      socket.on("connect", () => {
        socketIsReady = true;
        console.log("connected");

        socket.emit("joinRoom", `client-${catalogo._id}`);
      });
      socket.on("joinedRoom", (room: string) => {
        console.log("joinedRoom", room);
      });

      socket.on("connect", () => {
        console.log("Connected");
      });

      socket.on("toClient", (data: { type: string; msg: any }) => {
        if (data?.type) {
          switch (data.type) {
            case "client_announcement":
              distpatch(
                announcementsSlice.actions.addFireAnnouncement(data.msg)
              );
              break;

            default:
              console.log("default", data);
              break;
          }
        }
      });
    }

    return () => {
      console.log("disconnecting");
      socketIsReady = false;
      socket.disconnect();
    };
  }, [catalogo, distpatch]);

  return null;
};
